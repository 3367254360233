import React from "react";

// Components
import SEO from "../components/seo";
import Layout from "../components/layout";

// SCSS
import { gutter } from "../style/style.module.scss";

// ReCaptcha
import { RunReCaptcha } from "../services/recaptcha";

const ResearchPage = (): JSX.Element => {
  // Render
  return (
    <Layout>
      <SEO title="Clinical Trials: Research and Development" />
      <RunReCaptcha action="trials" />
      <div className={gutter}>
        <h1>Research &#38; Development</h1>

        <h2>Clinical Trials</h2>
        <p>
          Safety and Tolerability of Fb-PMT in Recurrent Glioblastoma
          <br />
          <a href="https://clinicaltrials.gov/ct2/show/NCT05226494?term=NanoPharmaceuticals&draw=2&rank=1">
            https://clinicaltrials.gov/ct2/show/NCT05226494?term=NanoPharmaceuticals&draw=2&rank=1
          </a>
        </p>
        <p>
          If you would like to be considered to participate in the trial, please
          email Farah Fasihuddin at Yale Cancer Center:{" "}
          <a href="mailto:farah.fasihuddin@yale.edu">
            farah.fasihuddin@yale.edu
          </a>
        </p>
      </div>
    </Layout>
  );
};

export default ResearchPage;
